export const LOADCONFIG = 'LOADCONFIG';

export const SCROLL = 'SCROLL';

export const GETSTORES = 'GETSTORES';
export const SAVESTORESLOCALLY = 'SAVESTORESLOCALLY';
export const UNMOUNTSTORESLISTS = 'UNMOUNTSTORESLISTS';
export const GETSTOREERR = 'GETSTOREERR';

export const GETSTOREGATEGORIES = 'GETSTOREGATEGORIES';
export const SAVESTORECATEGORIESLOCALLY = 'SAVESTORECATEGORIESLOCALLY';

export const SETSELECTEDSTORE = 'SETSELECTEDSTORE';
export const SETSELECTEDITEM = 'SETSELECTEDITEM';

export const GETITEMIOPTIONS = 'GETITEMIOPTIONS';
export const SAVEOPTIONSLOCALLY = 'SAVEOPTIONSLOCALLY';

export const GETITEMEXTRAS = 'GETITEMEXTRAS';
export const GETVARIANTEXTRAS = 'GETVARIANTEXTRAS';

export const SETGETTINGVARIANTSTOTRUE = 'SETGETTINGVARIANTSTOTRUE';
export const SAVEVARIANEXTRASTSLOCALLY = 'SAVEVARIANEXTRASTSLOCALLY';
export const SAVEITEMEXTRASLOCALLY = 'SAVEITEMEXTRASLOCALLY';
export const SETGETTINGITEMEXTRASTRUE = 'SETGETTINGITEMEXTRASTRUE';

export const ITEMWILLUNMOUNT = 'ITEMWILLUNMOUNT';

export const SETVARIANTITEMPRICE = 'SETVARIANTITEMPRICE';
export const ADDTOBASKET = 'ADDTOBASKET';
export const REMOVEFROMTOBASKET = 'REMOVEFROMTOBASKET';

export const INCREMENTCOUNT = 'INCREMENTCOUNT';

export const EMPTYBASKET = 'EMPTYBASKET';

export const SENDORDER = 'SENDORDER';

export const ORDERSUCCESS = 'ORDERSUCCESS';
export const ORDERFAILED = 'ORDERFAILED';
export const LOGINWILLUNMOUNT = 'LOGINWILLUNMOUNT';
export const SENDINGORDER = 'SENDINGORDER';

export const BASKETWILLUNMOUNT = 'BASKETWILLUNMOUNT';

export const RESETORDERLOGIC = 'RESETORDERLOGIC';